import React,{useState} from 'react'
import OrderDetailForm from './OrderDetailForm'
import { useCurrent } from '../../../hooks';
import OrderDetailsList from './OrderDetailsList';

const OrderDetails = ({order,reloadOrderHandler}) => {
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();

    return (
        <div>
            {order?.order_details.length > 0 && <OrderDetailsList items={order?.order_details} /> }
            <OrderDetailForm
                currentTenantId = {currentTenantId}
                currentCompanyId = {currentCompanyId}
                currentBranchId = {currentBranchId}
                currentLangId = {currentLangId}
                userId = {userId}
                order = {order}
                reloadOrderHandler = {reloadOrderHandler}
                />
        </div>
    )
}

export default OrderDetails