import React from 'react'

const MwTabViewHead = ({tabTitleList=[],tabActive,setTabActive,type}) => {
    return (
        <div className={`flex text-sm text-slate-600 ${ type === 'free' ? '  ' : '' } `}>
            {
                tabTitleList.map((tab,index) =>(
                    <div key={index} onClick={() =>setTabActive(tab.value) } className={` cursor-default px-2  ml-1   ${ type === 'free' ? ' rounded-2xl py-1 mb-2' : 'py-2 rounded-t-xl' } ${tab.value === tabActive ? ' font-bold bg-slate-300' : 'bg-slate-200'}`}> {tab.label}</div>
                ))
            }
        </div>
    )
}

export default MwTabViewHead