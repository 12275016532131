import React from 'react'
import {  MwCard } from '../../../components/ui'
import MwStepper from '../../../components/ui/stepper/MwStepper'
import OrderDetails from './OrderDetails'

const OrderShow = ({order,reloadOrderHandler,stages}) => {
    const _stages = stages?.map(st =>({
        label:st.name_ar,
        value: st.id
    }))
    const _attachList = order?.order_attachments
    return (
        <div className='text-slate-600'>

            {_stages && <MwStepper steps={_stages} progress={order?.stage_id} /> }
            <hr className='mb-5 mt-3 mx-3' />
            <MwCard>
                <div className='flex flex-1   items-top gap-10'>
                    <div className='flex-1 flex gap-10'>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>رقم امر التشغيل</span>
                                <span className='text-sm font-bold'>{order?.order_no}</span>
                            </div>
                        </div>
                        <div className='flex-1 flex gap-3  border-e pe-7'>
                            <div className='flex flex-col'>
                                <span className='text-xs'>وصف امر التشغيل</span>
                                <span className='text-xs font-bold '>{order?.order_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className=' flex gap-3'>
                        <div className='flex flex-col text-center border-e pe-7'>
                            <span className='text-xs'>رقم عرض السعر</span>
                            <span className='text-xs font-bold'>{order?.quote_no || 'لا يوجد'}</span>
                        </div>
                    </div>
                    <div className=' flex gap-3'>
                        <div className='flex flex-col text-center border-e pe-7'>
                            <span className='text-xs'>رقم الفاتورة</span>
                            <span className='text-xs font-bold'>{order?.invoice_no || 'لا يوجد'}</span>
                        </div>
                    </div>
                    <div className=' flex gap-3'>
                        <div className='flex flex-col text-center border-e pe-7'>
                            <span className='text-xs'> تاريخ الانشاء</span>
                            <span className='text-xs font-bold'>{order?.created_at.substr(0,10)}</span>
                        </div>
                    </div>
                    <div className=' flex gap-3'>
                        <div className='flex flex-col text-center border-e pe-7'>
                            <span className='text-xs'> تاريخ التسليم</span>
                            <span className='text-xs font-bold'>{order?.delivery_date || 'غير محدد'}</span>
                        </div>
                    </div>
                    <div className=' flex gap-3'>
                        <div className='flex flex-col text-center pe-7'>
                            <span className='text-xs'> الحالة </span>
                            <span className='text-xs font-bold'>{order?.order_status.name_ar }</span>
                        </div>
                    </div>
                </div>
            </MwCard>
            <MwCard>
                <div className='flex-1 flex flex-col gap-2 justify-start items-start'>
                    {/* <div className='font-bold text-xs'>تفاصيل الطباعة</div> */}
                    <div className='w-full flex flex-1   items-top gap-10'>
                        <div className='flex-1 flex gap-3  border-e pe-7'>
                            <div className='flex flex-col '>
                                <span className='text-xs'>المصمم</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.desginer_name || 'لم يتم تحديد مصمم'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>وزن الورق</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.paper_weight || 'لا يوجد'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>نوع السلوفان</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.solofan_type || 'لا يوجد'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>نوع الطباعة</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.printing_type || 'لا يوجد'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>اليوفي</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.uvi || 'لا يوجد'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>البصمة</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.touch || 'لا يوجد'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center  border-e pe-7'>
                                <span className='text-xs'>المقاس النهائي</span>
                                <span className='text-xs font-bold '>{order?.printing_class?.final_size || 'لا يوجد'}</span>
                            </div>
                        </div>
                        <div className=' flex gap-3'>
                            <div className='flex flex-col text-center pe-7'>
                                <span className='text-xs'>الفنيش</span>
                                <span className='text-xs font-bold'>{order?.printing_class?.order_finish || 'لا يوجد'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </MwCard>
            <MwCard>
                <div className='flex-1 flex flex-col gap-2 justify-start items-start'>
                    <div className='font-bold text-xs'>تفاصيل امر التشغيل</div>
                    <div className='text-sm'>{order?.notes || 'لا يوجد تفاصيل لامر التشغيل'}</div>
                </div>
            </MwCard>
            <MwCard>
                <div className='flex-1 flex flex-col gap-2 justify-start items-start'>
                    <div className='font-bold text-xs'>الملفات المرفقة</div>
                    <div className='text-sm'>{
                        _attachList?.length > 0 ? 
                    _attachList.map( (attach,i) => <li className='px-2 text-xs' key={i}>
                        <a href={attach.location} target='_blank'>{attach.name}</a>
                        
                        </li>) : <div className='text-xs'>لا توجد ملفات مرفقة لامر التشغيل</div>
                    }</div>
                </div>
            </MwCard>
            {/* <hr className='my-5' /> */}
            <OrderDetails 
                order = {order}
                reloadOrderHandler = {reloadOrderHandler}
                />
        </div>
    )
}

export default OrderShow