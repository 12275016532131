import {useEffect, useState} from 'react'
import usePost from './usePost';

const useUploadFile = (allowTypes,path='',type='file',withPreview=false) => {
    const [location,setLocation] = useState(null);
    const [data,setData] = useState(null);
    const [loading,setLoading] = useState(false);
    const [error,setError]  = useState(null); 
    const {data:resultUploads,loading:loadingUpload,postData:upload} = usePost(false);

    const handleUpload = async(event) => {
        setLoading(true)
        const file = event.target.files[0] || null;
        console.log(file);
        if(file){
            if(allowTypes.find(type => type ===  file.type)){
                if(type === 'image' && withPreview){
                    setLocation(URL.createObjectURL(file));
                }
                const fileName = file.name;
                try {
                    console.log('file => ',file);
                    const _formData = new FormData();
                    _formData.append('myfile',file,fileName);
                    upload(`${process.env.REACT_APP_UPLOADS_API_BASE_URL}/${path}`,_formData);
                } catch (error) {
                    setLoading(false);
                    console.error('Error uploading image:', error);
                }
    
            }else{
                setLoading(false);
                console.log('error image type:', file.type);
            }
        }
    };

    useEffect(() =>{
        
        if(resultUploads){
            resultUploads.data.location && setLocation(resultUploads.data.location)
            setLoading(false);
            setData(resultUploads.data)
            console.log('DataUploadFile =>',resultUploads.data);
        }
    },[resultUploads]);

  return {location,data,loading,error,handleUpload}
}

export default useUploadFile

