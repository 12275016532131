import React from 'react'

const MwCard = (props) => {
    return (
        <div 
            className={ `flex ${props.isFlexCol && 'flex-col'} my-3 p-3 bg-slate-200/25 justify-center items-top rounded-lg `}
            >
                {props.children}
        </div>
    )
}

export default MwCard