import React from 'react'

const OrderDetailsListItem = ({item}) => {
    return (
        <div className='flex gap-5 p-2 border-t my-2 justify-between items-center text-sm'>
            <div>{item?.notes}</div>
            <div>
                <div className='text-sm text-center pb-1 text-slate-400'>{ new Date(item?.created_at).toLocaleString()}</div>
                <div className='flex gap-6 text-xs'>
                    <div className='flex flex-col text-center border-e px-3'> 
                        <span>مرحلة التشغيل  </span> 
                        <span className='font-bold'>{item?.order_stage.name_ar}</span>
                    </div>
                    <div className='flex flex-col text-center'> 
                        <span>حالة التشغيل  </span> 
                        <span className='font-bold'>{item?.order_status.name_ar}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetailsListItem