import React from 'react'

const MwBadge = ({type,label}) => {

    let custStyle = '';
    switch (type) {
        case 'dark':
            custStyle = 'bg-slate-500';
            break;
        case 'light':
            custStyle = 'bg-slate-200';
            break;
        case 'sucess':
            custStyle = 'bg-green-500';
            break;
        default:
            custStyle = 'bg-slate-300';
    }

    return (
        <div className={`text-center px-2 pb-1 rounded-lg font-bold ${custStyle }`} > {label} </div>
    )
}

export default MwBadge