import React from 'react'


const DashBoard = () => {
    // const [t] = useTranslation('global')
    // const {currentSettings,currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    // let salesCurrentYear = 0;
    // const date = new Date();
    // const currentMonth = date.getMonth() + 1;
    // const currentYeay = date.getFullYear();


    return (
        <>
        
        </>
    )
}

export default DashBoard