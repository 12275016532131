import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import OrderForm from './components/OrderForm'
import { useCurrent, useFetch } from '../../hooks'
import OrdersList from './components/OrdersList'
import { MwSpinnerButton, NoDataFound } from '../../components/ui'
import MwTabView from '../../components/ui/tab/MwTabView'
import MwTabViewHead from '../../components/ui/tab/MwTabViewHead'
import MwTabViewBody from '../../components/ui/tab/MwTabViewBody'
import OrderShow from './components/OrderShow'

const Orders = ({initMode}) => {

    const id = useParams()?.id; 
    const [mode,setMode] = useState(initMode)
    const [stagesTab,setStagesTab] = useState()
    const [tabStageActive,setTabStageActive] = useState('all')
    const [statusTab,setStatusTab] = useState()
    const [tabStatusActive,setTabStatusActive] = useState('all')
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    
    const {data:dataShowOrder,loading:loadingShowOrder,refreshHandler:reloadShow} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/orders/show/${id}`,false);
    const {data:dataOrders,loading:loadingOrders,refreshHandler} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/orders/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataStages,loading:loadingStages} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/stages/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataStatus,loading:loadingStatus} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/status/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);

    const reloadOrderHandler = () => {
      reloadShow();
    }
    useEffect(() =>{
      if(id){
        reloadShow();
      }
    },[id]);

    useEffect(() =>{
        setMode(initMode)
        if(mode === 'Listing'){
          refreshHandler();
        }
        // if(mode === 'Show' && id){
        //   reloadShow();
        // }
        console.log('mode => ', mode);
    },[initMode]);

    useEffect(() =>{
      if(dataOrders){
        console.log('dataOrders => ', dataOrders);
      }
    },[dataOrders]);

    useEffect(() =>{
      if(dataStages){
        const _stage = dataStages.data.map(stage => ({
          label: stage.name_ar,
          value: stage.id
        }));
        setStagesTab([{ label: 'جميع المراحل' , value:"all" },..._stage])
      }
    },[dataStages]);

    useEffect(() =>{
      if(dataStatus){
        const _status = dataStatus.data.map(item => ({
          label: item.name_ar,
          value: item.id
        }));
        setStatusTab([{ label: 'الكل' , value:"all" },..._status])
      }
    },[dataStatus]);

    useEffect(() =>{
      if(dataShowOrder){
        console.log('dataShowOrder => ',dataShowOrder)
      }
    },[dataShowOrder]);

  return (
    <div>
        { mode === 'AddNew' && <OrderForm 
                                mode={mode}  
                                setMode={setMode}
                                currentTenantId = {currentTenantId}
                                currentCompanyId = {currentCompanyId}
                                currentBranchId = {currentBranchId}
                                currentLangId = {currentLangId}
                                userId = {userId}
                                />}
        {
          mode === 'Listing' ? loadingOrders ? <MwSpinnerButton label='جاري جلب اوامر التشغيل...' /> :
                              <div className=''>
                                <MwTabView>
                                    <div className='w-full  flex justify-between items-center'>
                                      <MwTabViewHead tabActive={tabStageActive} setTabActive={setTabStageActive} tabTitleList={stagesTab} />
                                      <MwTabViewHead type="free" tabActive={tabStatusActive} setTabActive={setTabStatusActive} tabTitleList={statusTab} />
                                    </div>
                                    <MwTabViewBody>
                                      { 
                                      dataOrders?.data?.data?.length > 0 ? 
                                        <OrdersList 
                                        orders = {dataOrders?.data}
                                        setMode={setMode}
                                        /> : <NoDataFound msg={'لا يوجد اوامر تشغيل'}/>
                                      }
                                    </MwTabViewBody>
                                </MwTabView>
                              </div> : <></>
        }
        {
          mode === 'Show' ? loadingShowOrder ? <MwSpinnerButton label='جاري جلب امر التشغيل...' />  : 
            <OrderShow 
              order = {dataShowOrder?.data}
              reloadOrderHandler = {reloadOrderHandler}
              stages = { dataStages?.data }
              /> : <></>
        }
    </div>
  )
}

export default Orders