import React from 'react'
import { DataListView,DataListViewHead,DataListViewBody } from '../../../components/ui/data-view'
import OrdersListItem from './OrdersListItem'

const OrdersList = ({orders,setMode}) => {
  return (
    <div>
      <DataListView>
        <DataListViewHead>
          <div className='w-16 text-center'>#</div>
          <div className='flex-1'>الوصف المختصر</div>
          <div className='flex-1'> العميل </div>
          <div className='flex-1'> المندوب </div>
          <div className='w-24 text-center' >تاريخ الانشاء</div>
          <div className='w-24 text-center'>تاريخ التسليم</div>
          <div className='w-32 text-center'>مرحلة التشغيل</div>
          <div className='w-32 text-center'>حالة التشغيل</div>
        </DataListViewHead>
        <DataListViewBody>
          {
            orders?.data?.map(order => <OrdersListItem key={order.id} setMode={setMode} order={order} />)
          }
        </DataListViewBody>
      </DataListView>
    </div>
  )
}

export default OrdersList