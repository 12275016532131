import React from 'react'
import OrderDetailsListItem from './OrderDetailsListItem'
import { MwCard } from '../../../components/ui'

const OrderDetailsList = ({items}) => {
    return (
        <MwCard>
            <div className='w-full'>
                <div className='text-xs font-bold  '>حركة امر التشغيل</div>
                {
                    items?.map((item) => <OrderDetailsListItem key={item.id} item={item}/>)
                }
            </div>
        </MwCard>
    )
}

export default OrderDetailsList