import React from 'react'

const MwTextArea = ({id,value,onChange,label,placeholder,rows,invalid,invalidMsg}) => {
    return (
        <>
            { label && 
                <label className='text-xs text-slate-500 ' htmlFor="inv_no">{label}</label>}
            <textarea 
                className={` text-xs text-slate-600 border w-full rounded border-1 p-2 ${ invalid ? ' border-red-500' : ' border-slate-200'} `}
                id={id}
                name={id} 
                value={value} 
                onChange={onChange}  
                placeholder={placeholder}
                rows={rows} 
                style={{resize: 'none'}}
                >
                                        
            </textarea>
            { invalidMsg && <div className='text-xs text-red-500'>{invalidMsg}</div> }

        </>
    )
}

export default MwTextArea