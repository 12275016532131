import React from 'react'
import { DataListViewRow } from '../../../components/ui/data-view'
import { MwBadge } from '../../../components/ui'
import { useNavigate, useParams } from 'react-router-dom'

const OrdersListItem = ({order,setMode}) => {
    const {tenant} = useParams();
    const navigate = useNavigate();
    return (
        <DataListViewRow onClick={()=>navigate(`/${tenant}/orders/Show/${order.id}`)}>
            <div className='w-20 text-center'>{order.order_no}</div>
            <div className='flex-1'>{order.order_name}</div>
            <div className='flex-1'>{order.customer_name || 'لا يوجد '}</div>
            <div className='flex-1'>{order.salesman_name || 'لا يوجد' }</div>
            <div className='w-24 text-center' >{order.created_at.substr(0,10)}</div>
            <div className='w-24 text-center'>{order.delivery_date || 'غير محدد' }</div>
            <div className='w-32 text-center'><MwBadge type={'light'} label={order.order_stage.name_ar}/> </div>
            <div className='w-32 text-center'><MwBadge type={'light'} label={order.order_status.name_ar} /> </div>
        </DataListViewRow>
    )
}

export default OrdersListItem