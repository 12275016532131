import React,{useState,useEffect} from 'react'
import { MwTextArea,MwInputFile, MwButton, MwSelector, MwCard } from '../../../components/ui'
import { useFetch, usePost, useUploadFile } from '../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';

const OrderDetailForm = ({order,reloadOrderHandler,currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId}) => {
    const [errors,setErrors] = useState({});
    const allowImgTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    // FormState
    const [formState,setFormState] = useState({
        notes:'',
        attachments:[],
        status: null,
        stage: null,

    });
    const [status,setStatus] = useState(); 
    const [stages,setStages] = useState(); 
    const {data:dataStatus,loading:loadingStatus} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/status/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataStages,loading:loadingStages} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/stages/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {location:selectedAttachments,data:dataUpload ,loading:loadingUploadAttachments,handleUpload:handleAttachmentsUpload} = useUploadFile(allowImgTypes,`production-orders-${currentTenantId}`)
    const {data:postResult,loading:postLoading,postData} = usePost();


    const validateForm = () => {
        setErrors({});
        let _errors = {};

        if(!formState?.notes){
            _errors  = {..._errors,notes:'يجب ادخال التفاصيل '};
            setErrors({..._errors})
        }

        if(_errors && Object.keys(_errors).length > 0 ){
            console.error(_errors);
            return true;
        }
        return false;
    }

    const onSubmitHandler = (type) => {
        if(validateForm()){
            return;
        }
        const _data = {
            order_master_id : order?.id,
            order_master_no : order?.order_no,
            notes : formState.notes,
            status_id : formState?.status?.value,
            stage_id : type === 'SaveAndConfirmNextStage' ?  ( order?.order_stage?.next_stage_id ?  order?.order_stage?.next_stage_id : formState?.stage?.value  ) : formState?.stage?.value ,
            created_by : userId,
            tenant_id : currentTenantId ,
            company_id : currentCompanyId,
            branch_id : currentBranchId,
            attachments : [... new Set(formState.attachments)] ,
        }
        console.log("_data",_data)
        postData(`${process.env.REACT_APP_API_BASE_URL}/order-details`,_data);
    }

    useEffect(() => {
        if(selectedAttachments){
            if(dataUpload?.originalname  ){
                formState?.attachments?.push({order_details_id:null,name:dataUpload?.originalname ,location:selectedAttachments})
                setFormState((state) =>  ({...state}) )
                console.log("formState.attachments",formState?.attachments)
            }
        }
    },[selectedAttachments]);

    useEffect(() => {
        if(dataStatus){
            console.log('dataStatus => ',dataStatus)
            const _statusHandle = dataStatus?.data.map(stage => ({
                label: stage.name_ar,
                value: stage.id
            }));
            setStatus(_statusHandle)
            const _init = _statusHandle.filter(item => item.value === order?.status_id)[0]
            setFormState((state)=> ({...state,status:_init}))
        }
    },[dataStatus]);


    useEffect(() => {
        if(dataStages){
            console.log('dataStages => ',dataStages)
            const _stageHandle = dataStages?.data.map(stage => ({
                label: stage.name_ar,
                value: stage.id
            }));
            setStages(_stageHandle)
            const _init = _stageHandle.filter(item => item.value === order?.stage_id)[0]
            setFormState((state)=> ({...state,stage:_init}))
        }
    },[dataStages]);
    useEffect(() => {
        if(postResult){
            console.log("Post Result",postResult)
            reloadOrderHandler()
        }
    },[postResult])

    return (
        <MwCard isFlexCol={true}>
            <div className='flex gap-2 pb-2 items-center'>
                <div className='flex-1 text-sm px-2 font-bold'>اضف حركة لامر التشغيل </div>
                <div></div>
                <div className="w-48 flex  flex-col ">
                    <MwSelector 
                        label={'المرحلة'}
                        initalValue={`اختار المرحلة...`}
                        _data={stages} 
                        dataType='labelValue'
                        withAddNew={false}
                        selectedItem={formState.stage}
                        setSelectedItem={(newValue) => setFormState((state)=> ({...state,stage:newValue})) }
                        />
                </div>
                <div className="w-48 flex  flex-col ">
                    <MwSelector 
                        label={'حالة امر التشغيل'}
                        initalValue={`اختار الحالة...`}
                        _data={status} 
                        dataType='labelValue'
                        withAddNew={false}
                        selectedItem={formState.status}
                        setSelectedItem={(newValue) => setFormState((state)=> ({...state,status:newValue}))}
                        />
                </div>
            </div>
            <div className='flex flex-col gap-2 '>
                    <MwTextArea
                        // label =' '
                        value={formState.notes}
                        onChange={(e) => setFormState((state)=> ({...state,notes:e.target.value}) )}
                        invalid = {!!errors?.notes}
                        invalidMsg = {errors?.notes}
                        rows={8}
                        />
                </div>
                <div className='flex gap-5 justify-between items-center'>
                    <div className='flex-1 py-3'>
                        <MwInputFile
                            label={'الملفات المرفقة'}
                            loading={loadingUploadAttachments} 
                            onChangeHandler={handleAttachmentsUpload}
                        />
                        <div className='flex gap-5 px-3'>
                            {
                                formState?.attachments?.map((attach,i) => <div key={i} >{attach.name}</div>)
                            }
                        </div>
                    </div>
                    <div className='flex gap-1 '>
                        <MwButton type="secondary" onClick = { ()=> onSubmitHandler('SaveAndConfirmNextStage')} >حفظ مع اعتماد الانتقال المرحلة التالية</MwButton>
                        <MwButton type="secondary" onClick = { ()=> onSubmitHandler('Save')} >حفظ</MwButton>
                    </div>
                </div>
        </MwCard>
    )
}

export default OrderDetailForm